<template>
  <v-card class="mb-5" >
    <div class="d-flex align-center justify-start">
      <div v-if="!$vuetify.breakpoint.xs">
        <v-card-text class="pa-3">
          <v-btn icon disabled>
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </v-card-text>
      </div>
      <v-btn class="pr-1 smaller" text @click="categoryFilter" large :tile="$vuetify.breakpoint.xs">
        <v-badge
          color="pink"
          :content="appliedCategories"
          :inline="!$vuetify.breakpoint.xs"
          v-show="appliedCategories"
        >
          Categories
        </v-badge>
        <span v-show="!appliedCategories">
          Categories
        </span>
        <v-icon v-show="!categoriesClicked && $vuetify.breakpoint.smAndUp">mdi-chevron-down</v-icon>
        <v-icon v-show="categoriesClicked && $vuetify.breakpoint.smAndUp">mdi-chevron-up</v-icon>
      </v-btn>
      <v-btn class="pr-1 smaller" text @click="skillFilter" large :tile="$vuetify.breakpoint.xs">
        <v-badge
          color="pink"
          :content="appliedSkills"
          :inline="!$vuetify.breakpoint.xs"
          v-show="appliedSkills"
        >
          Skills
        </v-badge>
        <span v-show="!appliedSkills">
          Skills
        </span>
        <v-icon v-show="!skillsClicked && $vuetify.breakpoint.smAndUp">mdi-chevron-down</v-icon>
        <v-icon v-show="skillsClicked && $vuetify.breakpoint.smAndUp">mdi-chevron-up</v-icon>
      </v-btn>
      <v-btn class="pr-1 smaller" text @click="collegeFilter" large :tile="$vuetify.breakpoint.xs">
        <v-badge
          color="pink"
          :content="appliedColleges"
          :inline="!$vuetify.breakpoint.xs"
          v-show="appliedColleges"
        >
          Colleges
        </v-badge>
        <span v-show="!appliedColleges">
          Colleges
        </span>
        <v-icon v-show="!collegesClicked && $vuetify.breakpoint.smAndUp">mdi-chevron-down</v-icon>
        <v-icon v-show="collegesClicked && $vuetify.breakpoint.smAndUp">mdi-chevron-up</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon class="mr-1 mr-sm-3" @click="removeFilters" v-show="appliedColleges || appliedSkills || appliedCategories"><v-icon>mdi-close-box</v-icon></v-btn>
    </div>
    <v-slide-y-transition hide-on-leave >
      <v-sheet v-if="skillsClicked || categoriesClicked || collegesClicked" elevation="0">
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="filtersForm" @submit.prevent :id="`filters-modal`" class="v-text-field--rounded form">
            <v-row v-if="categoriesClicked">
              <v-col v-if="$apollo.queries.categories.loading" :cols="12">
                <v-card>
                  <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col v-else>
                <div class="d-flex flex-wrap">
                  <v-checkbox hide-details class="mr-2" v-model="newCategories" :label="item.name" v-for="(item, i) in userCategories" :key="i" :value="item.id"></v-checkbox>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="skillsClicked">
              <v-col v-if="$apollo.queries.skills.loading">
                <v-card>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col v-else>
                <v-autocomplete
                  ref="skillsFilterProfiles"
                  v-model="newSkills"
                  :items="skills"
                  :search-input.sync="searchInput"
                  @input="searchInput = null"
                  :loading="loading"
                  outlined
                  hide-no-data
                  flat
                  hide-selected
                  item-text="name"
                  item-value="id"
                  auto-select-first
                  chips
                  solo
                  hide-details
                  deletable-chips
                  label="Skills"
                  :menu-props="autocompleteMenuProps"
                  multiple
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row v-if="collegesClicked">
              <v-col v-if="$apollo.queries.colleges.loading">
                <v-card>
                  <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col v-else>
                <v-autocomplete
                  ref="college"
                  auto-select-first
                  flat
                  v-model="newColleges"
                  :items="colleges"
                  :loading="loading"
                  outlined
                  hide-no-data
                  hide-selected
                  solo
                  item-text="name"
                  multiple
                  deletable-chips
                  hide-details
                  chips
                  item-value="id"
                  label="College / University"
                  :menu-props="autocompleteMenuProps"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-btn @click.prevent="applyFilters" block color="secondary" large class="mt-5">
              Apply Filters
            </v-btn>
          </v-form>
        </v-card-text>
      </v-sheet>
    </v-slide-y-transition>
  </v-card>
</template>

<script>
import breakPoints from '../../mixins/breakPoints'
import autocomplete from '../../mixins/autocomplete'

const CATEGORIES = require('../../graphql/queries/categories.graphql')
const SKILLS = require('../../graphql/queries/skills.graphql')
const COLLEGES = require('../../graphql/queries/colleges.graphql')

export default {
  apollo: {
    categories: {
      query: CATEGORIES,
      skip () {
        return !this.categoriesClicked
      }
    },
    colleges: {
      query: COLLEGES,
      skip () {
        return !this.collegesClicked
      }
    },
    skills: {
      query: SKILLS,
      skip () {
        return !this.skillsClicked
      }
    }
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      categoriesClicked: false,
      collegesClicked: false,
      skillsClicked: false,
      newCategories: [],
      newSkills: [],
      newColleges: [],
      searchInput: null,
      appliedCategories: 0,
      appliedSkills: 0,
      appliedColleges: 0
    }
  },
  computed: {
    userCategories () {
      return this.categories && this.categories.map(item => item.term)
    },
    userSelected () {
      return this.currentUser.profile.project_category.map(item => item.id) || []
    }
  },
  methods: {
    categoryFilter () {
      this.collegesClicked = false
      this.skillsClicked = false
      this.categoriesClicked = !this.categoriesClicked
    },
    collegeFilter () {
      this.categoriesClicked = false
      this.skillsClicked = false
      this.collegesClicked = !this.collegesClicked
    },
    skillFilter () {
      this.collegesClicked = false
      this.categoriesClicked = false
      this.skillsClicked = !this.skillsClicked
    },
    applyFilters () {
      this.$emit('apply', {
        categories: this.newCategories,
        skills: this.newSkills,
        colleges: this.newColleges
      })
      // applied Filters
      this.appliedCategories = this.newCategories.length
      this.appliedColleges = this.newColleges.length
      this.appliedSkills = this.newSkills.length
      // click event
      this.skillsClicked = false
      this.collegesClicked = false
      this.categoriesClicked = false
    },
    removeFilters () {
      this.$emit('apply', {
        categories: [],
        skills: [],
        colleges: []
      })
      // categories
      this.newCategories = []
      this.appliedCategories = 0
      this.categoriesClicked = false
      // college
      this.newColleges = []
      this.appliedColleges = 0
      this.collegesClicked = false
      // skills
      this.newSkills = []
      this.appliedSkills = 0
      this.skillsClicked = false
    }
  },
  mixins: [breakPoints, autocomplete]
}
</script>

<style scoped lang="scss">
  @media (max-width: 400px) {
    .smaller {
      font-size: 0.8rem;
      padding: 0 5px !important;
      i {
        font-size: 1rem;
      }
    }
  }

</style>
