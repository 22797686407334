<template>
  <v-hover class="mb-5" v-slot:default="{ hover }">
    <v-card :elevation="hover ? 4 : 1" :to="{name: 'profile', params: {id: user.ID}}" class="d-flex">
      <v-card-text class="pa-1">
        <v-list three-line>
          <v-list-item>
              <v-list-item-avatar size="60" :color="user.meta.et_avatar_url ? '' : 'info'">
              <v-img :lazy-src="user.meta.et_avatar_url" :src="user.meta.et_avatar_url" v-if="user.meta.et_avatar_url"/>
              <span class="white--text headline" v-else>{{ user.meta.first_name[0].toUpperCase() }}{{ user.meta.last_name[0].toUpperCase() }}</span>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center text-h6">{{ `${user.meta.first_name} ${user.meta.last_name}` }} <v-icon color="success" class="ml-1" v-show="user.meta.final_status === 'confirm'">mdi-check-decagram</v-icon></v-list-item-title>
              <v-list-item-subtitle class="my-1 font-weight-medium" v-if="user.profile && user.profile.college[0]">{{ user.profile.college[0].name }}</v-list-item-subtitle>
              <v-list-item-subtitle class="my-1 font-weight-medium" v-if="user.profile">{{ user.meta.location }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-list-item-content class="pa-0">
              <div v-if="user.profile">
                <v-chip v-for="(skill, i) in user.profile.skill" :key="i" class="mr-2 mb-2" small outlined label color="info">{{ skill.name }}</v-chip>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
        <v-btn fab depressed text>
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import breakPoints from '../../mixins/breakPoints'
export default {
  props: {
    userData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      user: this.userData.data
    }
  },
  mixins: [breakPoints]
}
</script>
