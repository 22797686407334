<template>
  <v-container fluid class="px-1 pa-sm-5">
    <filters @apply="handleFilters" :current-user="currentUser" />
    <profiles :users="users.list" v-if="users"/>
    <div class="skeleton-loaders">
      <skeleton @triggerIntersected="showMore" v-show="!$apollo.queries.users.loading && !allFetched" :loaders="1"/>
      <skeleton v-show="$apollo.queries.users.loading && !allFetched" :loaders="1" />
    </div>
    <div v-if="allFetched && users.list.length === 0" class="text-center">
      <h3 class="text-center mb-5">😐 Sorry, we can't find any profiles with these filters.</h3>
      <v-avatar size="320" max-width="100%" rounded>
        <v-img :src="require('@/assets/images/sorry.gif')" />
      </v-avatar>
    </div>
  </v-container>
</template>

<script>
import Profiles from '../components/Profiles'
import Skeleton from '../components/Utils/Skeleton'
import USERS from '../graphql/users.graphql'
import Filters from '../components/Profiles/Filters'

export default {
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      page: 1,
      number: 7,
      allFetched: false,
      order: 'DESC',
      orderby: 'user_registered',
      skills: [],
      college: [],
      categories: []
    }
  },
  apollo: {
    users: {
      query: USERS,
      variables () {
        return {
          query: {
            paged: 1,
            number: this.number,
            order: this.order,
            orderby: this.orderby,
            skills: this.skills,
            college: this.college,
            categories: this.categories,
            role: 'freelancer'
          }
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    async showMore () {
      this.page++
      try {
        // Fetch more data and transform the original result
        const res = await this.$apollo.queries.users.fetchMore({
          // New variables
          variables: {
            query: {
              paged: this.page,
              number: this.number,
              order: this.order,
              orderby: this.orderby,
              skills: this.skills,
              college: this.college,
              categories: this.categories,
              role: 'freelancer'
            }
          },
          // Transform the previous result with new data
          updateQuery: (previousResult, { fetchMoreResult }) => {
            return {
              users: {
                __typename: previousResult.users.__typename,
                // Merging the list
                list: [...previousResult.users.list, ...fetchMoreResult.users.list]
              }
              // meta: {
              //   __typename: previousResult.meta.__typename,
              //   // Merging the tag list
              //   list: [...fetchMoreResult.meta.list]
              // }
            }
          }
        })
        this.allFetched = res.data.users.list.length === 0
      } catch (e) {
        console.log(e)
        return e
      }
    },
    handleFilters (filters) {
      this.users = []
      this.page = 1
      this.allFetched = false
      this.categories = filters.categories
      this.college = filters.colleges
      this.skills = filters.skills
    }
  },
  metaInfo () {
    return {
      title: 'Profiles | Gradbee'
    }
  },
  components: {
    Skeleton,
    Filters,
    Profiles
  }
}
</script>

<style scoped>

</style>
