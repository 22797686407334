<template>
  <div>
    <single-profile v-for="(user, i) in users" :key="i" :userData="user"/>
  </div>
</template>

<script>
import SingleProfile from '../../components/Profiles/SingleProfile'
export default {
  props: {
    users: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SingleProfile
  }
}
</script>
